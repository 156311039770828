import { render, staticRenderFns } from "./douyinI.vue?vue&type=template&id=f9eb48a6&scoped=true"
import script from "./douyinI.vue?vue&type=script&lang=js"
export * from "./douyinI.vue?vue&type=script&lang=js"
import style0 from "./douyinI.vue?vue&type=style&index=0&id=f9eb48a6&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f9eb48a6",
  null
  
)

export default component.exports